<template>
  <navbar
    :show-zasilkovna-logo="isCsLocale"
    :show-shipment-tracking="!isIndexPage"
    :is-mobile="viewport.isLessThan('lg')"
  />

  <main>
    <slot />
  </main>

  <div v-if="!!alertBadge" class="error-badge-wrapper container-fluid p-0 mt-lg-16 mb-lg-8">
    <div class="navbar-container container-fluid container-lg">
      <div class="row m-0">
        <div class="col-12 p-0">
          <molecule-alert-badge
            v-bind="alertBadge"
            visible
            class="mx-0"
            with-shadow
            with-close-btn
            @close="globalStore.clearMoleculeAlertBadge()"
          >
            <template #description>
              {{ alertBadge.text }}
            </template>
          </molecule-alert-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'nuxt/app'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Locales } from '~common/enums'
import type { AlertBadgeProps } from '~common/types'
import { useGlobalStore } from '~stores/global.store.ts'

const MoleculeAlertBadge = defineAsyncComponent(
  () => import('~components/molecules/MoleculeAlertBadge.vue'),
)
const Navbar = defineAsyncComponent(() => import('~components/navbar/Navbar.vue'))

const { locale } = useI18n()
const route = useRoute()
const localePath = useLocalePath()
const globalStore = useGlobalStore()
const viewport = useViewport()

const alertBadge = ref<AlertBadgeProps | null>(null)

const isCsLocale = computed(() => {
  return locale.value === Locales.CS
})

const isIndexPage = computed(() => {
  return localePath(route.path) === localePath('index')
})

onMounted(() => {
  alertBadge.value = globalStore.moleculeAlertBadge
})

watch(
  () => globalStore.moleculeAlertBadge,
  (val) => {
    alertBadge.value = val
  },
)
</script>

<style lang="scss" scoped>
.error-badge-wrapper {
  min-height: 77px;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;

  @media (min-width: $media-lg) {
    top: 71px;
    z-index: 2;
  }
}
</style>
